import React from 'react'
import { Link } from 'gatsby'
import './styles.scss'

class Toggler extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          collapse: false,       
        }
    }

    render() {
        
        return(
            <div>
            <div className={`menu-icon ${this.state.collapse === true ? 'active' : ''}`} onClick={() => this.collapseNav()}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className={`overlay ${this.state.collapse === true ? 'open' : ''}`} id="overlay">
                <nav className="overlay-menu">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="#services">Services</Link></li>
                        <li><Link to="#works">Works</Link></li>
                        <li><Link to="#contact">Contact</Link></li>
                    </ul>
                </nav>
                <Link to="#contact" className="btn-new-project">Start your project</Link>
            </div>
          </div>
        )
    }

    collapseNav() {
        console.log("Open Menu")
        if (!this.state.collapse) {
            this.setState({ collapse: true })
        } else {
            this.setState({ collapse: false })
        }
    }
}

export default Toggler