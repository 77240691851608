import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import Toggler from './Toggler'
import { Link } from "gatsby"

var scrollToElement = require('scroll-to-element')

class Navbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          collapse: false,
          sticky: false,
          sections: this.props.sections ? this.props.sections : ['home', 'about', 'services', 'portfolio', 'clients', 'contact']
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (window.pageYOffset >= 50 && this.state.sticky) {
            if (this.state.collapse !== nextState.collapse) {
                return true
            }
            return false
        } else {
            return true
        }
    }

    handleScroll = event => {
        if (window.pageYOffset >= 50) {
            this.setState({ sticky: true })
        } else {
            this.setState({ sticky: false })
        }
    }

    collapseNav() {
        console.log(this.state, 'col')
        console.log("Open Menu")
        if (!this.state.collapse) {
            this.setState({ collapse: true })
        } else {
            this.setState({ collapse: false })
        }
    }
    

    render() {
/*  padding: 20px 0;*/
        const NavbarWrapper = styled.div`
            position: absolute;
            z-index: 1;
            width: 100%;
            padding: 20px 0;
            z-index: 100;           
            &.sticky {
                position: fixed;
                background-color: rgba(0,0,0,.8);
                padding: 0 0;
                @media (max-width: 500px) {
                  
                }
            }
        `
        /*display: block;
        padding: 0;*/
        const NavbarContainer = styled(Container)`
            display: flex;
            position: relative;
            transition: 200ms ease all;
            @media (max-width: 500px) {
             
            }
            
        `
/*   flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
                background-color: rgba(0,0,0,.8);
                margin-top: 20px;*/
        const Nav = styled.nav`
            flex: 0 0 80%;
            max-width: 80%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: 200ms ease all;
            @media (max-width: 500px) {
             
                &.hidden_mobile {
                    display: none;
                }
            }
        `
/*@media (max-width: 500px) {
                flex: 0 0 100%;
                max-width: 100%;
                justify-content: center;
            }*/
        const LogoWrapper = styled.div`
            flex: 0 0 20%;
            max-width: 20%;
            display: flex;
            z-index: 99999;
            justify-content: flex-start;
            align-items: center;     
            
            &.sticky {
                img {
                    height: 65px;
                    margin: 10px 0px;
                }
            }           
        `

        const Logo = styled.img`
            height: 80px;            
            transition: 200ms ease all;
            @media (max-width: 1023px) {
                height: 70px;
            }
            &:hover {
                transform: scale(1.1);
            }
        `

        const NavInner = styled.div`
            justify-content: flex-end;
            margin-right: 10px;
        `

        /*const Toggler = styled.button`
            color: #fff;
            position: absolute;
            right: 0;
            top: 0;
            @media (min-width: 500px) {
                display: none;
            }
        `*/
        
        /*${this.state.collapse === true ? 'expand' : 'hidden_mobile'}*/
        return(
            <NavbarWrapper className={`header${this.state.sticky === true ? ' sticky' : ''}`}>
                <NavbarContainer>
                    <LogoWrapper className={`logo${this.state.sticky === true ? ' sticky' : ''}`}>
                        <Link to="/" itemProp="url">
                            <Logo src="/img/Logo_Aride_White.png" alt="Aride Logo" />
                        </Link>
                    </LogoWrapper>
                    <Nav className={`navbar navbar-expand-sm expand`}>
                        <NavInner className={`navbar-collapse collapse ${this.state.collapse === true ? 'show' : ''}`}>
                            <div className="navbar-nav">{this.navItems()}</div>
                        </NavInner>   
                        <Toggler onClick={() => this.collapseNav()} />                                                    
                    </Nav>                     
                </NavbarContainer>
            </NavbarWrapper>
        )
    }

    navigate(id) {
        if (this.props.scroll) {
            const el = document.getElementById(id)
            scrollToElement(el, {
                offset: 0,
                ease: 'in-out-expo',
                duration: 2000
            })
        } else {
            window.location.href = `./#${id}`;
        }
    }

    navItems() {
        const NavItem = styled.button`
            background: none;
            border: none;
            color: #fff;
            text-transform: capitalize;
            font-weight: 500;
            margin: 10px 5px;
            transition: .5s;
            &:hover {
                color: #e7225a;
            }
            &:focus {
                outline: none;
            }
            @media (min-width: 501px) and (max-width: 1023px) {
                font-size: 11px;
                margin: 2px;
            }
        `
        
        return this.state.sections.map((value, index) => {
            return (
                <NavItem key={index} onClick={() => this.navigate(value)}>
                    {value}
                </NavItem>
            )
        })
    }
}

export default Navbar